import React from 'react';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

const RadioGroup = (props) => {
  const {
    field,
    meta: { error, touched, value },
    label,
    options,
    disabled,
    additionalInfo,
    moreText,
  } = props;

  return (
    <fieldset className="checkbox-group">
      <Prompt {...{ label, additionalInfo, moreText }} />
      <div
        className={options.length >= 7 ? 'options-group-flex' : 'options-group'}
      >
        {options.map((option, i) => (
          <div key={option.value} className="user-option-box option-tab">
            <input
              {...field}
              name={field.name}
              type="radio"
              value={option.value.toString()}
              // eslint-disable-next-line eqeqeq
              checked={option.value == value}
              disabled={disabled}
              id={`${field.name}_option_${i}`}
            />
            <label htmlFor={`${field.name}_option_${i}`}>{option.label}</label>
          </div>
        ))}
      </div>
      <FieldError {...{ error, touched }} />
    </fieldset>
  );
};

export default wrapField(RadioGroup);
