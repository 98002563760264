import React from 'react';
import {Form, Formik} from "formik";
import {RadioGroup, TextField} from "../../../components/inputs";
import {csrfHeader, validations} from "../../../../helperFunctions";
import axios from "axios";
import {formValidations} from "../../../helpers/moduleHelper";

class SecuredMessageForm extends React.Component {
  constructor(props) {
    super(props);

    var options = [];
    if (props.notificationContacts) {
      if (props.notificationContacts.email) {
        options.push({label: props.notificationContacts.email, value: "email"})
      }
      if (props.notificationContacts.sms) {
        options.push({label: props.notificationContacts.sms, value: "sms"})
      }
    }
    this.state = {options: options, showNextButton: options.length === 1}
  }

  handleSubmit = (values, url) => {
    const {setLoader, patient, scheduled, locale} = this.props;
    const {options} = this.state;
    const selectedValue = options.find((o) => o.value === values.method_type);
    setLoader(true);
    return axios({
      method: 'post',
      url: url,
      data: { sm_id: values.secured_message_id, patient_id: values.patient_id, method_type: values.method_type,
        email: (values.method_type === "email" && selectedValue) ? selectedValue.label : null ,
        phone: (values.method_type === "sms" && selectedValue) ? selectedValue.label : null, locale: locale},
      headers: csrfHeader,
    })
      .then(response => {
        if (response.status === 200 || response.status === 302) {
          window.location.href = `/access_message?patient_id=${patient.id}&secured_message_id=${values.secured_message_id}&scheduled=${scheduled}`;
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }
    
  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    if (!values.method_type) {
      errors.method_type = fieldErrorMessage;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  supportedLanguages() {
    const {supportedLanguages} = this.props;
    return (
      supportedLanguages &&
      supportedLanguages.map((language, index) => (
        <span className="col-md-12" key={index}>
            {index !== 0 && (
              <span className={`divider divider-${index % 2}`}>|</span>
            )}
          <a href={`/select_language/${language.tag}`}>
              <div
                className="espanol-text-inner language_link txt-green"
                style={{marginBottom: '15px'}}
              >
                {language.selection_label}
              </div>
            </a>
          </span>
      ))
    );
  }

  render() {
    const {stringValues, securedMessage, patient, submitURL, locale, tridiuumLogo} = this.props;
    const {options, showNextButton} = this.state;
    return (
      <div>
        <div className="powered-logo-wrapper"/>
        <div className="form-wrapper send-code-section" id="error-survey-react">
          <div className="symphony-logo-wrapper">
            <img src={tridiuumLogo ? `/assets/${tridiuumLogo.image}` : "/assets/polaris-landing-logo.png"} alt="Tridiuum Logo" title={tridiuumLogo.title} className={tridiuumLogo.class}/>
          </div>

          <div id="send-code-wrapper" className="accesscode-wrapper error-wrapper">
            <div>
              <h2> {stringValues && stringValues["select_delivery_method_text"]}</h2>
            </div>
            <div className="email-phn-wrapper">
              <Formik
                initialValues={{
                  secured_message_id: (securedMessage && securedMessage.id) ? securedMessage.id : '',
                  patient_id: (patient && patient.id) ? patient.id : '',
                  method_type: (options && options.length === 1) ? options[0].value : ''
                }}
                onSubmit={(values, {setSubmitting}) => {
                  setTimeout(() => {
                    this.handleSubmit(values, submitURL).then(r => {

                    })
                    setSubmitting(false);
                  }, 400);
                }}
                validate={this.validate}
              >
                {props => (
                  <Form id="send-code-form" className="form-wrapper protected-site-cont">
                    <div className={`rows`}>
                      {options && (<div className="row clear">
                        <div className="form-group">
                          <RadioGroup
                            name="method_type"
                            options={options}
                          />
                        </div>
                      </div>)}
                      <div className="row clear">
                        <div className="form-group form-footer">
                          <button type="submit" disabled={!showNextButton} className={`btn btn-lg btn-primary btn-block`} name=" action" id="
                                                                       btn-next">{stringValues && stringValues['send_code']}
                          </button>
                        </div>
                      </div>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className='language-selector error-msg'>
          {this.supportedLanguages()}
        </div>
      </div>
    );
  }
}

export default SecuredMessageForm;
